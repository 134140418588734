import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { useLocation } from 'react-router-dom';
import logo from "../../../assests/images/logo.svg"
export const Header = () => {

  const location = useLocation();
  
  const [activeRoute, setActiveRoute] = useState("")
  const [sideActive, setSideActive] = useState(false)
  const [activeGoals, setActiveGoals] = useState(false)
  
  useEffect(() => {
      if(location.pathname) {
        window.scrollTo(0, 0)
        console.log(location.pathname)
        setActiveRoute(location.pathname)
      }
  },[location])
  let history = useHistory()
    return (
      <header
      data-height={90}
      data-sticky-height={40}
      data-responsive-height={90}
      data-transparent-skin=""
      data-header-style={1}
      data-sticky-style="fixed"
      data-sticky-offset="header"
      id="mk-header-1"
      className="mk-header header-style-1 header-align-left  toolbar-true menu-hover-5 sticky-style-fixed mk-background-stretch boxed-header "
      role="banner"
      itemScope="itemscope"
      itemType="https://schema.org/WPHeader"
    >
      <div className="mk-header-holder">
        <div className="mk-header-toolbar">
          <div className="mk-grid header-grid">
            <div className="mk-header-toolbar-holder">
              <span className="mk-header-tagline">
                5 Branches across Egypt. Elsheikh zayed. Masr elgdida
                , 5th settlement, Maadi, Madinty and Rehab
              </span>
            </div>
          </div>
        </div>
        <div className="mk-header-inner add-header-height">
          <div className="mk-header-bg " />
          <div className="mk-toolbar-resposnive-icon">
            <svg
              className="mk-svg-icon"
              data-name="mk-icon-chevron-down"
              data-cacheid="icon-62380b1d257e4"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1792 1792"
            >
              <path d="M1683 808l-742 741q-19 19-45 19t-45-19l-742-741q-19-19-19-45.5t19-45.5l166-165q19-19 45-19t45 19l531 531 531-531q19-19 45-19t45 19l166 165q19 19 19 45.5t-19 45.5z" />
            </svg>
          </div>
          <div className="mk-grid header-grid">
            <div
              className="mk-header-nav-container one-row-style menu-hover-style-5"
              role="navigation"
              itemScope="itemscope"
              itemType="https://schema.org/SiteNavigationElement"
            >
              <nav className="mk-main-navigation js-main-nav">
                <ul id="menu-one-page-01" className="main-navigation-ul">
                  <li
                    id="menu-item-6152"
                    className={`menu-item menu-item-type-post_type menu-item-object-page menu-item-home no-mega-menu ${activeRoute == "/home" && "current-menu-item current_page_item"}`}
                  >
                    <a
                    
                      className="menu-item-link js-smooth-scroll"
                      onClick={() => history.push("/home")}
                    >
                      Home
                    </a>
                  </li>
                  <li
                    id="menu-item-6577"
                    className={`menu-item menu-item-type-post_type menu-item-object-page page_item page-item-4741 no-mega-menu ${activeRoute == "/aboutus" ? "current-menu-item current_page_item" : ""}`}
                  >
                    <a
                    
                      className="menu-item-link js-smooth-scroll"
                      onClick={() => history.push("/aboutus")}
                    >
                      About
                    </a>
                  </li>
                  <li
                    id="menu-item-8061"
                    className={`menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children no-mega-menu ${ activeRoute == "/goal-one" || activeRoute == "/goal-two" || activeRoute == "/goal-three" || activeRoute == "/goal-four" || activeRoute == "/goal-five" || activeRoute == "/goal-six" ? "current-menu-item current_page_item" : ""}`}
                  >
                    <a
                     className="menu-item-link js-smooth-scroll" >
                      Goals
                    </a>
                    <ul style={{}} className="sub-menu ">
                      <li
                        id="menu-item-8165"
                        className="menu-item menu-item-type-post_type menu-item-object-post"
                        onClick={() => history.push("/goal-one")}
                      >
                        <a
                        
                          className="menu-item-link js-smooth-scroll"
                          
                        >
                          Build muscles and increase your strenght
                        </a>
                      </li>
                      <li
                        id="menu-item-8098"
                        className="menu-item menu-item-type-post_type menu-item-object-post"
                        onClick={() => history.push("/goal-two")}

                      >
                        <a
                        
                          className="menu-item-link js-smooth-scroll"
                        >
                          Weight Loss
                        </a>
                      </li>
                      <li
                        id="menu-item-8110"
                        className="menu-item menu-item-type-post_type menu-item-object-post"
                        onClick={() => history.push("/goal-three")}

                      >
                        <a
                        
                          className="menu-item-link js-smooth-scroll"
                        >
                          Reducing cellulite
                        </a>
                      </li>
                      <li
                        id="menu-item-8105"
                        className="menu-item menu-item-type-post_type menu-item-object-post"
                        onClick={() => history.push("/goal-four")}

                      >
                        <a
                        
                          className="menu-item-link js-smooth-scroll"
                        >
                          Shape Your Body
                        </a>
                      </li>
                      <li
                        id="menu-item-8114"
                        className="menu-item menu-item-type-post_type menu-item-object-post"
                        onClick={() => history.push("/goal-five")}

                      >
                        <a
                        
                          className="menu-item-link js-smooth-scroll"
                        >
                          Improve your physical condition
                        </a>
                      </li>
                      <li
                        id="menu-item-8126"
                        className="menu-item menu-item-type-post_type menu-item-object-post"
                        onClick={() => history.push("/goal-six")}

                      >
                        <a
                        
                          className="menu-item-link js-smooth-scroll"
                        >
                          Reduce Back Pain
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li
                    id="menu-item-22909"
                    className={`menu-item menu-item-type-post_type menu-item-object-page no-mega-menu ${activeRoute == "/results" ? "current-menu-item current_page_item" : ""}`}
                    onClick={() => history.push("/results")}

                  >
                    <a
                    
                      className="menu-item-link js-smooth-scroll"
                      
                    >
                      Results
                    </a>
                  </li>
                  <li
                    id="menu-item-8418"
                    className={`menu-item menu-item-type-post_type menu-item-object-page no-mega-menu ${activeRoute == "/faqs" ? "current-menu-item current_page_item" : ""}`}
                    onClick={() => history.push("/faqs")}
                  >
                    <a
                    
                      className="menu-item-link js-smooth-scroll"
                    >
                      FAQ
                    </a>
                  </li>
                  <li
                    id="menu-item-6428"
                    className={`menu-item menu-item-type-post_type menu-item-object-page no-mega-menu ${activeRoute == "/pricing" ? "current-menu-item current_page_item" : ""}`}
                    onClick={() => history.push("/pricing")}

                  >
                    <a
                    
                      className="menu-item-link js-smooth-scroll"
                    >
                      Pricing
                    </a>
                  </li>
                  <li
                    id="menu-item-6652"
                    className={`menu-item menu-item-type-post_type menu-item-object-page no-mega-menu ${activeRoute == "/contact" ? "current-menu-item current_page_item" : ""}`}
                    onClick={() => history.push("/contact")}
                  >
                    <a
                    
                      className="menu-item-link js-smooth-scroll"
                    >
                      Contact
                    </a>
                  </li>
                  {/* <li
                    id="menu-item-6749"
                    className="menu-item menu-item-type-post_type menu-item-object-page no-mega-menu"
                  >
                    <a
                    
                      className="menu-item-link js-smooth-scroll"
                      href="../blog/index.html"
                    >
                      Blog
                    </a>
                  </li> */}
                </ul>
              </nav>
              {/* <div className="main-nav-side-search">
                <a
                
                  className="mk-search-trigger add-header-height mk-fullscreen-trigger"
                  href="#"
                >
                  <i className="mk-svg-icon-wrapper">
                    <svg
                      className="mk-svg-icon"
                      data-name="mk-icon-search"
                      data-cacheid="icon-62380b1d2780c"
                      style={{ height: 16, width: "14.857142857143px" }}
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 1664 1792"
                    >
                      <path d="M1152 832q0-185-131.5-316.5t-316.5-131.5-316.5 131.5-131.5 316.5 131.5 316.5 316.5 131.5 316.5-131.5 131.5-316.5zm512 832q0 52-38 90t-90 38q-54 0-90-38l-343-342q-179 124-399 124-143 0-273.5-55.5t-225-150-150-225-55.5-273.5 55.5-273.5 150-225 225-150 273.5-55.5 273.5 55.5 225 150 150 225 55.5 273.5q0 220-124 399l343 343q37 37 37 90z" />
                    </svg>
                  </i>
                </a>
              </div> */}
            </div>
            <div className={`mk-nav-responsive-link ${sideActive && "is-active"}`} onClick={() => setSideActive(!sideActive)}>
              <div className="mk-css-icon-menu">
                <div className="mk-css-icon-menu-line-1" />
                <div className="mk-css-icon-menu-line-2" />
                <div className="mk-css-icon-menu-line-3" />
              </div>
            </div>
            <div className=" header-logo fit-logo-img add-header-height  ">
              <a
                title="Ems Bodyfit">
                <img
                  className="mk-desktop-logo dark-logo "
                  src={logo}
                  width={100}
                />
              </a>
            </div>
          </div>
          <div className="mk-header-right" />
        </div>
        {sideActive && <div className="mk-responsive-wrap" style={{display:"block"}}>
          <nav className="menu-one-page-01-container">
            <ul id="menu-one-page-2" className="mk-responsive-nav">
              <li
                id="responsive-menu-item-6152"
                className="menu-item menu-item-type-post_type menu-item-object-page menu-item-home"
              >
                <a
                
                  className="menu-item-link js-smooth-scroll"
                  onClick={() => history.push("/home")}
                >
                  Home
                </a>
              </li>
              <li
                id="responsive-menu-item-6577"
                className="menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-4741 current_page_item"
              >
                <a
                
                  className="menu-item-link js-smooth-scroll"
                  onClick={() => history.push("/aboutus")}
                >
                  About
                </a>
              </li>
              <li
                id="responsive-menu-item-8061"
                className="menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children"
              >
                <a
                 className=" menu-item-link js-smooth-scroll"                   onClick={() => setActiveGoals(!activeGoals)}
                 >
                  Goals
                </a>
                <span className="mk-nav-arrow mk-nav-sub-closed">
                  <svg
                    className="mk-svg-icon"
                    data-name="mk-moon-arrow-down"
                    data-cacheid="icon-62380b1d29023"
                    style={{ height: 16, width: 16 }}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <path d="M512 192l-96-96-160 160-160-160-96 96 256 255.999z" />
                  </svg>
                </span>
                {activeGoals && <ul className="sub-menu" style={{display:"block"}}>
                  <li
                    id="responsive-menu-item-8165"
                    className="menu-item menu-item-type-post_type menu-item-object-post"
                  >
                    <a
                    
                      className="menu-item-link js-smooth-scroll"
                      onClick={() => history.push("/goal-one")}
                    >
                      Build muscles and increase your strenght
                    </a>
                  </li>
                  <li
                    id="responsive-menu-item-8098"
                    className="menu-item menu-item-type-post_type menu-item-object-post"
                  >
                    <a
                    
                      className="menu-item-link js-smooth-scroll"
                      onClick={() => history.push("/goal-two")}
                    >
                      Weight Loss
                    </a>
                  </li>
                  <li
                    id="responsive-menu-item-8110"
                    className="menu-item menu-item-type-post_type menu-item-object-post"
                  >
                    <a
                    
                      className="menu-item-link js-smooth-scroll"
                      onClick={() => history.push("/goal-three")}
                    >
                      Reducing cellulite
                    </a>
                  </li>
                  <li
                    id="responsive-menu-item-8105"
                    className="menu-item menu-item-type-post_type menu-item-object-post"
                  >
                    <a
                    
                      className="menu-item-link js-smooth-scroll"
                      onClick={() => history.push("/goal-four")}
                    >
                      Shape Your Body
                    </a>
                  </li>
                  <li
                    id="responsive-menu-item-8114"
                    className="menu-item menu-item-type-post_type menu-item-object-post"
                  >
                    <a
                    
                      className="menu-item-link js-smooth-scroll"
                      onClick={() => history.push("/goal-five")}
                    >
                      Improve your physical condition
                    </a>
                  </li>
                  <li
                    id="responsive-menu-item-8126"
                    className="menu-item menu-item-type-post_type menu-item-object-post"
                  >
                    <a
                    
                      className="menu-item-link js-smooth-scroll"
                      onClick={() => history.push("/goal-six")}
                    >
                      Reduce Back Pain
                    </a>
                  </li>
                </ul>}
              </li>
              <li
                id="responsive-menu-item-22909"
                className="menu-item menu-item-type-post_type menu-item-object-page"
              >
                <a
                
                  className="menu-item-link js-smooth-scroll"
                  onClick={() => history.push("/results")}
                >
                  Results
                </a>
              </li>
              <li
                id="responsive-menu-item-8418"
                className="menu-item menu-item-type-post_type menu-item-object-page"
              >
                <a
                
                  className="menu-item-link js-smooth-scroll"
                  onClick={() => history.push("/faqs")}
                >
                  FAQ
                </a>
              </li>
              <li
                id="responsive-menu-item-6428"
                className="menu-item menu-item-type-post_type menu-item-object-page"
              >
                <a
                
                  className="menu-item-link js-smooth-scroll"
                  onClick={() => history.push("/pricing")}
                >
                  Pricing
                </a>
              </li>
              <li
                id="responsive-menu-item-6652"
                className="menu-item menu-item-type-post_type menu-item-object-page"
              >
                <a
                
                  className="menu-item-link js-smooth-scroll"
                  onClick={() => history.push("/contact")}
                >
                  Contact
                </a>
              </li>              
            </ul>
          </nav>          
        </div>}
      </div>
      <div className="mk-header-padding-wrapper" />
    </header>
    )
}