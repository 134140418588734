import React, { useEffect, useState } from 'react'
import Modal from "react-modal"

import { faTimes } from '@fortawesome/free-solid-svg-icons';

import "../../../assests/styles/Modal.scss"
import "./index.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AvatarModal from './avatarModal';
import { useDispatch, useSelector } from 'react-redux';
import PhoneNumber from './Number';
import Dropdown from "react-dropdown";
import * as XLSX from 'xlsx/xlsx.mjs';
import ACTION_PHONE_NUMBER_RESET from '../../../actions/GuestCheckoutForm/PhoneNumber/ACTION_PHONE_NUMBER_RESET';
import ACTION_PHONE_NOT_INVALID from '../../../actions/PhoneNumberValidation/Invalid/ACTION_PHONE_NOT_INVALID';

const AddClientModal = (props) => {


         
  const phoneNumber = useSelector((state) => state.phoneNumber.phone_number);
  const phoneIsValid = useSelector((state) => state.phoneIsValid.phone_valid);

  const dispatch = useDispatch()

    const {
        user,
        inviteManyUsers,
        clientModal,
        setClientModal,
        setClientName,
        clientName,
        setClientMobile,
        clientMobile,
        inviteUser,
        emailAlreadyRegistered,
        invalidEmail,
        setEmailAlreadyRegistered,
        companyPhoneNumber,
        setClientLocation,
        clientLocation,
        clientType,
        setClientType,

        // AvatarModal props
        takeAPhotoSelected,
        changeTakeAPhotoSelected,
        webcamURI,
        changeWebcamURI,
        imageUploaded,
        changeImageUploaded,
        imagePreviewAvailable,
        changeImagePreviewAvailable,
        handleDeletedPreviewImage,
        handleImageUploaded,
        addProfilePhotoClicked,
        setAddProfileClicked,
        updateClientFunc,
        dataArr,
setDataArr,
many,
setMany,
        
    } = props

    const readUploadFile = (e) => {
      e.preventDefault();
      if (e.target.files) {
          const reader = new FileReader();
          reader.onload = (e) => {
              const data = e.target.result;
              const workbook = XLSX.read(data, { type: "array" });
              const sheetName = workbook.SheetNames[0];
              const worksheet = workbook.Sheets[sheetName];
              const json = XLSX.utils.sheet_to_json(worksheet);
              setDataArr(json)
          };
          reader.readAsArrayBuffer(e.target.files[0]);
      } 
  }

  let formatNumber = (n) => {
    let nu = n.toString()
    if(nu?.length == "10") {
    return `(0${nu[0]}${nu[1]}) ${nu[2]}${nu[3]}${nu[4]} - ${nu[5]}${nu[6]}${nu[7]}${nu[8]}${nu[9]}`
    } else {
      return null
    }
    }
let arrTypes = ["leeds",
  "income",
  "whatsapp",
  "walkIn",
  "recommendation",
  "old",
  "lastSession",
  "unactive"]

  const arrLocations = ['maadi', "madinty", "Rehab", "zayd", "masr", "tagamoa"]


  useEffect(() => {
    if(dataArr) {
      let newArr = dataArr.map((x, i, a) => {
        let picked = (({ clientName, clientNumber, clientBranch, clientType, clientFeedback }) => ({ clientName, clientNumber: formatNumber(clientNumber), clientBranch, clientType, clientFeedback  }))(x)
        if(picked) {
          if(
            picked.clientName &&
            picked.clientBranch &&
            arrLocations?.includes(picked?.clientBranch?.toLowerCase()) &&
            picked.clientNumber && 
            picked.clientType &&
            arrTypes?.includes(picked.clientType)
            ) {
             return picked
            } else { 
              return null
            } 
        } else {   
          return null  
        } 
      })
      if(newArr) {
        inviteManyUsers(newArr?.filter(x => x))
      }
    }
  },[dataArr]) 

return(
<Modal
        isOpen={clientModal ? true : false}
        style={{
          overlay: {
            position:"fixed",
            zIndex:"99999999",
            display:"flex",
            justifyContent:"center", 
            alignItems:"center",
        },
          content: {
            position: "fixed",
            zIndex: 10000,
            opacity: 0.99,
            height: "100%",
            backdropFilter: "blur(5px)",
            WebkitBackdropFilter: "blur(5px)",
            padding:"40px",
            borderRadius: "none",
            width: "auto",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            border: "none",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            background: "rgba(0, 0, 0, 0.5)",
          },
        }}
      >
<FontAwesomeIcon
                className="modal_x"
                icon={faTimes}
                onClick={() => {
                    
                  changeWebcamURI("");
                  changeImagePreviewAvailable(
                    false
                  );
                  changeImagePreviewAvailable(
                    false
                  );
                  changeTakeAPhotoSelected(false);
                  changeImageUploaded("");
                  setClientModal(null)
                    setClientName("")
                    setClientMobile("")
                    setEmailAlreadyRegistered("")
                    setClientLocation("")
                    dispatch(ACTION_PHONE_NUMBER_RESET())
                    dispatch(ACTION_PHONE_NOT_INVALID())

                     }}
              />
        { clientModal && <div className="modal task-modal">
               

          <h1 className="header" style={{width:"100%", justifyContent:"space-between"}}>
            {clientModal?.includes("Edit") ? clientModal : `Invite a ${clientModal}`}
            {user?.user?.email == "rawnaa.rona21@gmail.com" || user?.user?.role == "admin" ? !clientModal?.includes("Edit") ? many ? <div style={{cursor:"pointer", color:"#EF1111"}} onClick={() => setMany(false)}>Single</div> : <div style={{cursor:"pointer", color:"#6db240"}} onClick={() => setMany(true)}>Many</div> : null : null}
            </h1>
          {many ? (
            <div style={{width:"100%", height:"100%", border:"1px dashed gray", display:"flex", justifyContent:"center", alignItems:"center", margin:"10px 0"}}>
              <input
              type="file"
              name="upload"
              id="upload"
              onChange={readUploadFile}
          />
            </div>
          ) : (
          <><div className="form-group-container task-form">
          {clientModal?.includes("Edit")&&<div className="form-row"> 
            <AvatarModal
                takeAPhotoSelected={takeAPhotoSelected}
                changeTakeAPhotoSelected={changeTakeAPhotoSelected}
                webcamURI={webcamURI}
                changeWebcamURI={changeWebcamURI}
                imageUploaded={imageUploaded}
                changeImageUploaded={changeImageUploaded}
                imagePreviewAvailable={imagePreviewAvailable}
                changeImagePreviewAvailable={changeImagePreviewAvailable}
                handleDeletedPreviewImage={handleDeletedPreviewImage}
                handleImageUploaded={handleImageUploaded}
                addProfilePhotoClicked={addProfilePhotoClicked}
                setAddProfileClicked={setAddProfileClicked}
            />
            </div>}
          <div className="form-row">
            <div className="form-collection">
              <div className="form-label">client Name</div>
              <input
                onChange={(e) => setClientName(e.target.value)}
                value={clientName}
                type="text"
                placeholder="Client name"
                className="form-input" 
              />
            </div>
            </div>
            {!clientModal?.includes("Edit") && <div className="form-row">
            <div className="form-collection">
            <PhoneNumber />            
            </div>
            </div>}
            <div className="form-row">
            <div className="form-collection">
            <div className="form-label">
                  Location
                </div>
                <Dropdown
                menuClassName="underMenu"
                  options={['Maadi', "Madinty", "Rehab", "Zayd", "Masr", "tagamoa"]}
                  onChange={(choice) =>
                    setClientLocation(choice.value)
                  }
                  value={clientLocation}
                  controlClassName="form-input"
                  
                /> 
          </div>
          </div>
          {!clientModal?.includes("Edit") && <div className="form-row">
            <div className="form-collection">
            <div className="form-label">
                  Type
                </div>
                <Dropdown
                menuClassName="underMenu"
                  options={["leeds","income", "whatsapp", "walkIn", "recommendation", "unactive", "old", "expired", "lastSession"]}
                  onChange={(choice) =>
                    setClientType(choice.value)
                  }
                  value={clientType}
                  controlClassName="form-input"
                  
                />
          </div>
          </div>}
            
          </div></>)}
          <div>
            <div
              className={`${clientModal?.includes("Edit") ? clientName && !emailAlreadyRegistered ? "auth-button addUser" : "auth-button addUser disable-button" : clientName && !emailAlreadyRegistered && phoneNumber && phoneIsValid ? "auth-button addUser": "auth-button addUser disable-button"}`}
              onClick={clientModal?.includes("Edit") ? clientName && !emailAlreadyRegistered ? updateClientFunc : null : clientName && !emailAlreadyRegistered && phoneNumber && phoneIsValid ? inviteUser : null }
            >
              
              {clientModal?.includes("Edit") ? "Update" : "Add"}

            </div>
          </div>
        </div>}
      </Modal>
  )}

  export default AddClientModal
       